import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/layout.tsx";
import Intro from "../components/context-magic/intro";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧در دست نگارش🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧🚧`}</p>
    <Intro mdxType="Intro" />
    <hr></hr>
    <h3>{`منابع`}</h3>
    <div dir="ltr" className="resources">
      <p>{`[`}{`1`}{`]`}{`: `}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      